<template>
    <div class="card">
        <h2>User Policy for Onomastics</h2>
        <p>Welcome to Onomastics ("the App"). This User Policy outlines the terms and conditions of using the App.</p>
        <h3>Data Collection</h3>
        <p>Onomastics does not collect any personal user information, including email addresses or names. The App is designed for users to search Kazakh names without requiring any user-specific data.</p>
        <h3>Data Protection</h3>
        <p>We take the privacy and security of user data seriously. Onomastics does not store or collect any personal information, and user searches are kept anonymous.</p>
        <h3>Cookies</h3>
        <p>Onomastics may use cookies to enhance user experiences. Users can configure their browsers to refuse cookies, but this may affect access to some of the App's services.</p>
        <h3><a href="mailto:bekmanova_gt@enu.kz">Contact: bekmanova_gt@enu.kz</a></h3>
        <p>If you have any questions or concerns about this User Policy, please contact us at [contact email or address].</p>
        <p>Last Updated: 31.10.2023</p> 
    </div>
</template>
 
